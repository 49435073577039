<template>
  <div class="iCountBox">
    <CountUp :endVal="number">{{ text }}</CountUp>
    <div v-html="nl2br(brief)" class="secondary--text text-center text-overflow-ep-6 ma-auto mt-5 iCountContent"></div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  computed: {
    text() {
      if (!this.item) return "";
      return this.item.text;
    },
    number() {
      if (!this.item) return 0;
      return +this.item.number;
    },
    brief() {
      if (!this.item) return "";
      return this.item.brief;
    },
  },
  components: {
    CountUp: () => import("./countUp.vue"),
  },
};
</script>